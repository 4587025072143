import React, { useContext, useState, useEffect,useMemo } from "react";

import * as styles from './section-overview-grid.module.scss';
import FilterGroup from "../../overview/FilterGroup/FilterGroup";
import { FilterContext } from "../../../utils/context";
import ReccomendedProduct from "../../Shared/ReccomendedProduct/ReccomendedProduct";
import ReccomendedProductQuickView from "../../Shared/ReccomendedProduct/ReccomendedProductQuickView";
import { useLocale, useDictionaryQuery } from "../../../hooks";

const SectionOverviewGrid = ({ filterGroups, products }) => {
  const [gridItems, setGridItems] = useState(null);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const { loadMoreProducts } = useDictionaryQuery();

  const [selectedFilters, setSelectedFilters] = useState([]);


  var setstatus=false;



  const buttonClassName = useMemo(() => {
    const showLoadMore = !showAll && gridItems?.length >= 9;
    return `${styles.loadMore} ${showLoadMore ? "" : styles.displayNone}`;
  }, [showAll, gridItems]);


  // useEffect(() => {
  //   const handleUserInteraction = () => {
  //     if (locale === 'en-US') {
  //       // Lazy load Bazaarvoice script only when needed
  //       const script = document.createElement("script");
  //       script.src = "https://apps.bazaarvoice.com/deployments/lipton-tea/main_site/production/en_US/bv.js";
  //       script.async = true;
  //       script.onload = () => {
  //         // Script loaded successfully, initialize Bazaarvoice
  //         // console.log('Bazaarvoice script loaded.');
  //       };
  //       script.onerror = (error) => {
  //         // Handle script loading errors
  //         // console.error('Error loading Bazaarvoice script:', error);
  //       };
  //       document.head.appendChild(script);
  //     }
  //   };

  //   window.addEventListener('click', handleUserInteraction);
  
  //   return () => {
  //     window.removeEventListener('click', handleUserInteraction);
  //   };
  // }, []);











  const locale = useLocale();
  const {
    formatFilter,
    teaTypesFilter,
    teaBenefitFilter,
    flavorFilter,
    highlightsFilter
  } = useContext(FilterContext);

  useEffect(() => {
    const prod = [...products.sectionThumbnail];
    setOriginalProducts(prod);
    filterByProductRange(prod);
  }, [formatFilter, teaTypesFilter, teaBenefitFilter, flavorFilter, highlightsFilter]);

  

  const getItemsFromFilter = (items, filterGroup) => {
    return items.filter(item => {
      let matched = false;
      if (item.filters) {
        item.filters.map(filter => {
          if (filterGroup.includes(filter.filterTitle && filter.filterTitle)) {
            matched = true;
            return false;
          }
        });
      }
      return matched;
    });
  };

  const filterByTeaTypes = array => {
    if (teaTypesFilter.length > 0) {
      const filteredProdsByTeaTypes = getItemsFromFilter(array, teaTypesFilter);
      setSelectedFilters([...selectedFilters, teaTypesFilter]);
      return filterByTeaBenefit(filteredProdsByTeaTypes);
    } else {
      return filterByTeaBenefit(array);
    }
  };

  const filterByTeaBenefit = array => {
    if (teaBenefitFilter.length > 0) {
      const filteredProdsByTeaBenefit = getItemsFromFilter(array, teaBenefitFilter);
      setSelectedFilters([...selectedFilters, teaBenefitFilter]);
      return filterByFlavor(filteredProdsByTeaBenefit);
    } else {
      return filterByFlavor(array);
    }
  };

  const filterByFlavor = array => {
    if (flavorFilter.length > 0) {
      const filteredProdsByFlavor = getItemsFromFilter(array, flavorFilter);
      setSelectedFilters([...selectedFilters, flavorFilter]);
      return filterByHighlights(filteredProdsByFlavor);
    } else {
      return filterByHighlights(array);
    }
  };

  const filterByHighlights = array => {
    if (highlightsFilter.length > 0) {
      const filteredProdsByHighlights = getItemsFromFilter(array, highlightsFilter);
      setSelectedFilters([...selectedFilters, highlightsFilter]);
      return createFilteredProducts(filteredProdsByHighlights);
    } else {
      return createFilteredProducts(array);
    }
  };

  const filterByProductRange = array => {
    if (formatFilter.length > 0) {
      const filteredProdsByFormat = getItemsFromFilter(array, formatFilter);
      setSelectedFilters([...selectedFilters, formatFilter]);
      return filterByTeaTypes(filteredProdsByFormat);
    } else {
      return filterByTeaTypes(array);
    }
  };


  const createFilteredProducts = async array => {
   
   if(locale === "tr-TR")
   {
    if (array.length === 0 && selectedFilters.length > 0) {

      const arr=[...formatFilter, ...teaTypesFilter,...teaBenefitFilter, ...flavorFilter, ...highlightsFilter]
     
      const filteredProducts = originalProducts.filter(product => {
       
        return product.filters?.some(filter =>
          arr.some(selectedFilter =>
            selectedFilter.includes(filter.filterTitle) 
          )
        );
      });
      setGridItems(filteredProducts);
    }
    else {
     
      setGridItems(array);
    }
   }
  else{
   const amountOfActiveFilters = formatFilter.concat(formatFilter, teaTypesFilter, teaBenefitFilter, flavorFilter, highlightsFilter).length;
    setGridItems(array)
   }
  };

  const handleShowAll = () => {
    setShowAll(true);
  };
 
 

 
  
  return (
    <>
        <div>
            {filterGroups ? <FilterGroup filterGroups={filterGroups} /> : ""}
            {
                gridItems?.length===0 ? (
                    <div>
                        <p>Not Found</p>
                    </div>
                ) : (
                    <div className={styles.productContainer}>
                        {
                            gridItems?.map((product: any, index: number) => {
                                return (
                                    showAll ? (
                                        <div className={styles.img_wrapper} key={index}>
                                            {
                                                (locale === "en-AE" || locale === "ar-AE" || locale === "en-EG" || locale === "ar-EG" || locale === "en-US" || locale === "nl-NL")
                                                    ? <ReccomendedProductQuickView
                                                        fluid={product?.thumbnailImage?.fluid}
                                                        text={product?.title}
                                                        richTextTitle={product?.richTextTitle}
                                                        showButton={true}
                                                        to={product?.slug}
                                                        longSubtitle={product?.longSubtitle?.longSubtitle}
                                                        subTitle={product?.subTitle}
                                                        productId={product?.productId}
                                                        index={index}
                                                        cta={product?.cta}
                                                    />
                                                    : <ReccomendedProduct
                                                        fluid={product?.thumbnailImage?.fluid}
                                                        text={product?.title}
                                                        showButton={true}
                                                        to={product?.slug}
                                                        longSubtitle={product?.longSubtitle?.longSubtitle}
                                                    />
                                            }
                                        </div>
                                    ) : index < 9 ? (
                                        <div className={styles.img_wrapper} key={index}>
                                            {
                                                (locale === "en-AE" || locale === "ar-AE" || locale === "en-EG" || locale === "ar-EG" || locale === "en-US" || locale === "nl-NL")
                                                    ? <ReccomendedProductQuickView
                                                        fluid={product?.thumbnailImage?.fluid}
                                                        text={product?.title}
                                                        richTextTitle={product?.richTextTitle}
                                                        showButton={true}
                                                        to={product?.slug}
                                                        longSubtitle={product?.longSubtitle?.longSubtitle}
                                                        subTitle={product?.subTitle}
                                                        index={index}
                                                        productId={product?.productId}
                                                        cta={product?.cta}
                                                    />
                                                    : <ReccomendedProduct
                                                        fluid={product?.thumbnailImage?.fluid}
                                                        text={product?.title}
                                                        showButton={true}
                                                        to={product?.slug}
                                                        longSubtitle={product?.longSubtitle?.longSubtitle}
                                                    />

                                            }
                                        </div>
                                    ) : null
                                )
                            })
                        }
                    </div>
                )
            }
            <div className={styles.buttonContainer} onClick={handleShowAll}>
        <button className={buttonClassName}>{loadMoreProducts}</button>
      </div>
        </div>
    </>
)
}
export default SectionOverviewGrid