import { graphql } from "gatsby";
import React from "react";
import Seo from "../components/seoGlobal";
import { useLocale } from "../hooks";
import GenericHero from "../components/Shared/Generic/Hero/GenericHero";
import SectionOverviewGrid from "../components/sections/section-overview-grid/sectionOverviewGrid";
import Schema from "../components/schema";
import ArticleContent from "../ArticlePage/ArticleContent/ArticleContent";
import { getPathname } from "../utils/functions";

const CategoryProducts = ({ location, pageContext, data }) => {
  const {
    heroImage,
    name,
    productCollection,
    seoDescription,
    seoTitle,
    slug,
    filterGroups,
    longSubtitle,
    headingbar,
  } = data.contentfulPageCategoryProducts;

  const { nodes: allLinks } = data.allContentfulPageCategoryProducts;
  const locale = useLocale();

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription?.seoDescription}
        lang={locale}
        hrefLangs={allLinks}
      />
      <Schema slug={getPathname(pageContext.node_locale, slug)} />

      <GenericHero
        title={heroImage.title}
        subtitle={heroImage?.description?.description}
        fluid={heroImage?.backgroundImage?.fluid}
        fluidMob={heroImage?.backgroundImageMobile?.fluid}
        firstChild={true}
      />
      
      {headingbar && <ArticleContent description={headingbar.description} />}
      <SectionOverviewGrid filterGroups={filterGroups} products={productCollection} />
    </>
  );
};



export const CategoryProductsQuery = graphql`
  query CategoryProducts($id: String!, $contentful_id: String!) {
    contentfulPageCategoryProducts(id: { eq: $id }) {
      name
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
      heroImage {
        id
        name
        title
        description {
          description
        }
        backgroundImage {
          title
          fluid(maxWidth: 1600, quality: 60) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        backgroundImageMobile {
          title
          fluid(quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      filterGroups {
        title
        filters {
          filterTitle
        }
        filterType
      }
      headingbar {
        description {
          raw
        }
      }
      productCollection {
        id
        title
        subtitle
        sectionThumbnail {
          ... on ContentfulPageProducts {
            id
            title
            thumbnailDescription {
              thumbnailDescription
            }
            slug
            thumbnailImage {
              fluid(maxWidth: 2200, quality: 70) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            thumbnailTitle
            richTextTitle{
              raw
            }
            filters {
              filterTitle
            }
            longSubtitle {
              longSubtitle
            }
            subTitle
            productId
            cta {
              title
              slug
            }
          }
        }
      }
    }
    allContentfulPageCategoryProducts(
      filter: {
        contentful_id: { eq: $contentful_id }
        id: { ne: $id }
        slug: { ne: null }
      }
    ) {
      nodes {
        slug
        node_locale
      }
    }
  }
`

export default CategoryProducts
