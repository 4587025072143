import React, { useState, useEffect, useRef } from "react"
import * as styles from "./reccomendedProductQuickView.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { useLocale, useLocalPath, useDictionaryQuery, useSearchQuery } from "../../../hooks"
import { RichText } from "../../../components/Layout/RichText/RichText"

const ReccomendedProductQuickView = ({
    fluid,
    text,
    richTextTitle,
    showButton,
    to,
    longSubtitle,
    subTitle,
    productId,
    index = null,
    cta
    // setShowResults,
    // showResults
}) => {
    const { shopNowButton, findOutMoreButton, quickViewButton } =
        useDictionaryQuery()
    const [height, setHeight] = useState(0)
    const ref = useRef(null)
   const locale = useLocale()

    const { productDoNotDisplayButton } = useSearchQuery()

    useEffect(() => {
        setHeight(ref?.current?.clientHeight)
    }, [])

    const [showResults, setShowResults] = useState(false)
    const showPopup = () => setShowResults(true)
    const hidePopup = () => setShowResults(false)

    const [desktop, setDesktop] = useState(
        window.matchMedia("(min-width: 767px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 767px)")
            .addEventListener("change", e => setDesktop(e.matches))
    }, [])


    const [tablet, setTablet] = useState(
        window.matchMedia("(min-width: 481px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 481px)")
            .addEventListener("change", e => setTablet(e.matches))
    }, [])

    useEffect(() => {
        if (showResults) {
            /**
         * Alert if clicked on outside of element
         */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowResults(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [showResults, ref]);

    if (to) {
        return (
            <div
                className={`${styles.reccomendedProduct} products-thumbnail`}
                ref={ref}
            >
                <Link to={useLocalPath(`/${to}`)}>
                    <Img fluid={fluid} alt={text} className={styles.reccomendedImg}></Img>
                </Link>
                {!richTextTitle ? <Link to={useLocalPath(`/${to}`)}>
                    <p className={styles.textBar}>{text}</p>
                </Link> :
                 <Link to={useLocalPath(`/${to}`)}>
                    <div className={styles.textBar}>
                        <RichText content={richTextTitle} />
                    </div>
                </Link>}
                { productId &&
                <div data-bv-show="rating_summary" data-bv-product-id={productId}>
                </div> }
                <button className={styles.btn__secondary} onClick={showPopup}>
                    {" "}
                    {quickViewButton}{" "}
                </button>

                {showResults && (
                    <div
                        className={styles.modal}
                        style={{
                            top: desktop ?                            
                                index + 1 <= 3 ? height : height * Math.ceil((index + 1) / 3)
                                
                                : tablet ?
                                index + 1 <= 2 ? height : height * Math.ceil((index + 1) / 2)
                                : height * Math.ceil((index + 1))
                        }}
                    >
                        <div className={styles.col_1}>
                            <Link to={useLocalPath(`/${to}`)}>
                                <Img
                                    fluid={fluid}
                                    alt={text}
                                    className={styles.reccomendedImg}
                                ></Img>
                            </Link>
                        </div>
                        <div className={styles.content}>
                            {" "}
                            <div className={styles.col_2}>
                                <Link to={useLocalPath(`/${to}`)}>
                                    <p className={styles.pop_title}>{text}</p>
                                </Link>
                                {longSubtitle &&
                                    <p className={styles.pop_des}>{longSubtitle}</p>}
                                {subTitle &&
                                    <p className={styles.pop_des}>{subTitle}</p>}

                                <div>
                                    <Link to={useLocalPath(`/${to}`)}>
                                        {showButton && <button> {findOutMoreButton} </button>}
                                    </Link>
                                </div>
                                <div>
                                    {/* <Link to={useLocalPath(`/${to}`)}>
                                        {showButton && <button> {shopNowButton}</button>}
                                    </Link> */}
                                    {
                                        productDoNotDisplayButton
                                            ? null
                                            : cta && cta[0] && cta[0].slug ?
                                                <Link target="_blank" to={`${cta[0].slug}`}>
                                                    <button className="btn-primary"> {cta && cta[0].title}</button>
                                                </Link> :
                                                ""
                                        // <button className="btn-primary"> {cta && cta[0].title}</button>
                                    }
                                </div>
                            </div>
                        </div>

                        <button className={styles.close} onClick={hidePopup}>
                            x
                        </button>
                    </div>
                )}
            </div>
        )
    } else {
        return (
            <div className={styles.reccomendedProduct}>
                <Img fluid={fluid} alt={text} className={styles.reccomendedImg}></Img>
                <p>{text}</p>
                {showButton && <button>{shopNowButton}</button>}
            </div>
        )
    }
}

export default ReccomendedProductQuickView
